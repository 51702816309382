$(function(){
toggleMenu();
mailUncrypt();
initBigGallery();
});



function goTo(id) {
var p = $("#"+ id).last();
var offset = p.offset();
var top = offset.top;
if($("nav").hasClass("open_nav")) {
    closeMenu();
}
//console.log(id);
//console.log(top);
top2 = parseInt(top) - 80;
//console.log(top2);
var body = $("html, body");
body.stop().animate({scrollTop:top2}, 800);
//console.log( "left: " + offset.left + ", top: " + offset.top );
  
}

function toggleMenu() {
    $('#menubutton').click(function(){
        if($("nav").is(':visible')){
            closeMenu();
        } else {
            $(this).addClass('open');
            $("nav").removeClass("normal_nav");
            $("nav").addClass('open_nav');
            $('nav').slideDown('fast');
            //$("#menubutton").fadeOut("fast");

            $("body").css("overflow", "scroll");
            $("header").css("border-bottom", "0px");
            $("#topright").css("display","block");
            $("header").css("position","absolute");
            $("main").css("display","none");
        }
    });
} 

function closeMenu() {
    $('nav').slideUp('fast');
    $("nav").addClass('normal_nav');
    $("nav").removeClass("open_nav");
    $("#menubutton").removeClass("open"); 
    $("body").css("overflow", "inherit");
    $("#menubutton").fadeIn("fast");
    $("#topright").css("display","none");
    $("header").css("position","fixed");
    $("main").css("display","block");
}

function unCrypt(o, a, x) {
    o.onmouseover = '';
    addr = '';
    a = unescape(a);
    for (c = 0; c < a.length; ++c) {
        addr += String.fromCharCode(a.charCodeAt(c) - x);
    }
    o.setAttribute('href', 'mailto:' + addr);
}

function mailUncrypt() {
    $(".mailUnCrypt").each(function(i, e) {
        $(e).mouseover(function() {
            if($(this).hasClass("mailUnCrypt")) {
            a = $(e).attr("href").substring(2);
            addr = '';
            a = unescape(a);
            for (c = 0; c < a.length; ++c) {
                addr += String.fromCharCode(a.charCodeAt(c) - 7);
            }
            $(this).attr('href', 'mailto:' + addr);
            $(this).removeClass("mailUnCrypt");
        }
            
        });
//        $(this).mouseover(unCrypt(this, $(this).attr("href"), 7));
    });
}

var rtime;
var timeout = false;
var delta = 200;
var w = 0;
$(window).load(function(){
    w = $(window).width();
});
$(window).resize(function() {
    if (w != $(window).width()){
        rtime = new Date();
        if (timeout === false) {
            timeout = true;
            setTimeout(resizeend, delta);
        }
    }
});

function resizeend() {
    if (new Date() - rtime < delta) {
        setTimeout(resizeend, delta);
    } else {
        timeout = false;
        $("#menubutton").removeClass("open");
        $("#menubutton").attr("style","");
        $("nav").attr("style","");
        $("nav").attr("style","");
        $("body").css("overflow", "inherit");
        $("#topright").css("display","block");
        $("header").css("position","fixed");
        $("main").css("display","block");
    }               
}
const firstPath = window.location.pathname.split('/')[1];
if (firstPath != "" && firstPath != "index" && firstPath != "search"){
    const pageBtn = document.getElementById(firstPath);
    pageBtn.classList.add("selected");
} else {
    const pageBtn = document.getElementById('home');
    pageBtn.classList.add("selected");
}
window.addEventListener('resize',resizeGallery);
// set width of gallery
if(window.innerWidth < 800){
    wsize = 1;
} else {
    wsize = 2;
}
var galleryCount = document.getElementsByClassName("gallery").length;
for (i = 1; i <= galleryCount; i++){
    var slides = document.getElementsByClassName("slides");
    //amount of images in gallery
    countSlides = slides.length;
    var width = countSlides*100/wsize+"%"
    var widthSlides = 100/countSlides;
    gallery = document.getElementById('gallery'+i);
    gallery.style.width = width;
    for(i2=0; i2 < slides.length; i2++){
        slides[i2].style.width = widthSlides+"%";
    }
    move(i,0);
}
window.addEventListener("hashchange", offsetAnchor);
window.setTimeout(offsetAnchor, 1);

function resizeGallery(){
    if(window.innerWidth < 800){
        wsize = 1;
    } else {
        wsize = 2;
    }
    for (i = 1; i <= galleryCount; i++){
        countSlides = slides.length;
        var width = countSlides*100/wsize;
        gallery = document.getElementById('gallery'+i);
        gallery.style.width = width+"%";
        var gleft = gallery.style.left.replace(/[^0-9]/g,'');
        if(gleft > width){
            if (width % 100 == 0){
                width = width-100;
                gallery.style.left = "-"+width+"%";
            } else {
                width = width-50;
                gallery.style.left = "-"+width+"%";
            }
        }
    }
}

/*
const kachel = document.getElementsByClassName('kachel');
if (kachel.length % 2 !== 0){
    const lastKachel = document.getElementById('kachel'+kachel.length);
    lastKachel.style.float = "none";
}
*/
function move(g,n) {
    var element = document.getElementById('gallery'+g);
    var l = document.getElementById('left'+g);
    var r = document.getElementById('right'+g);
    var m = element.style.left;
    var w = element.style.width;
    var ok = true;
    m = m.replace(/[^0-9]/g,'');
    w = w.replace(/[^0-9]/g,'');
    var move = 0;
    if (n > 0) {
        move = parseInt(m) - 100;
    } else if (n < 0){
        move = parseInt(m) + 100;
        if (move >= w){
            ok = false;
        }
    } else {
        ok = false;
    }
    if (ok == true) {
        element.style.left = "-"+move+"%";
    }
    if (parseInt(element.style.left) + parseInt(w) <= 100){
        l.style.display = "unset";
        r.style.display = "none";
    } else {
        l.style.display = "unset";
        r.style.display = "unset";
    }
    if (parseInt(element.style.left) == 0){
        l.style.display = "none";
    }
}
function initBigGallery(){
    $('.biggallerybox').each(function() {
        console.log($(this));
        var nr = 0;
        var newnr = 0;
        var images = $(this).find('.gallery_images');
        var cnt = images.length - 1;
        var galleryid = $(this).attr('data-id');
        $(this).find('.gallery_next').click(function(){
            nr = parseInt($(this).parent().attr('data-img'));
            if (nr == cnt) {
                newnr = 0;
            } else {
                newnr = nr + 1;
            }
            changeGalleryImg(galleryid,newnr);
       });
        $(this).find('.gallery_previous').click(function(){
            nr = parseInt($(this).parent().attr('data-img'));
            if (nr == 0) {
                newnr = cnt;
            } else {
                newnr = nr - 1;
            }
            changeGalleryImg(galleryid,newnr);
       });
    });
}

var changeGalleryImg = function(galleryid,newnr) {
    var img = $('#galleryimg'+galleryid+'_'+newnr);
    $('#gallery'+galleryid).attr('src',img.attr('data-img'));
    $('#gallery'+galleryid).attr('alt',img.attr('data-alt'));
    $('#gallery'+galleryid).attr('title',img.attr('data-alt'));
    $('#gallery'+galleryid).parent().attr('data-img',newnr);
}

function showResult(id){
    document.getElementById('result'+id).classList.add('autoHeight');
}
function hideResult(id){
    document.getElementById('news'+id).classList.remove('autoHeight');
    document.getElementById('replace'+id).style.display = 'inline';
    document.getElementById('long'+id).style.display = 'none';
}
function showArticle(id){
    document.getElementById('news'+id).classList.add('autoHeight');
    document.getElementById('long'+id).style.display = 'inline';
    document.getElementById('short'+id).style.display = 'none';
    document.getElementById('replace'+id).style.display = 'none';
    //document.getElementById('show'+id).style.display = 'inline';
    //$("#btn"+id).slideUp();
    //$("#wholetext"+id).css('display','inline');
    /*
    $("#long"+id).slideDown(500, function() {
        $(this).css('display', 'inline')
    });
    */
}
function hideArticle(id){
    document.getElementById('news'+id).classList.remove('autoHeight');
    document.getElementById('replace'+id).style.display = 'inline';
    document.getElementById('short'+id).style.display = 'inline';
    document.getElementById('long'+id).style.display = 'none';
    //$("#wholetext"+id).css('display','-webkit-box');
    //$("#btn"+id).slideDown();
    //$("#wholetext"+id).slideUp();
    //document.getElementById('news'+id).scrollIntoView({ behavior: 'smooth'});
}
var countsize = 0
function changeFontSize(size,controller, set = 0){
    if ((countsize < 2 && size > 0) || (countsize > -1 && size < 0) || set != 0){
        const oldsize = countsize + 1;
        if (set == 0){
            if (size < 0) {
                countsize--;
            } else {
                countsize++;
            }
        }
        const elementp = document.querySelectorAll( 'a,h1,h2,h3,h4,h5,a,p,input,ul' );
        const nava = document.getElementsByClassName('normal_nav');
        const navsize = countsize + 1;
        for (i = 0; i < nava.length; i++){
            nava[i].classList.remove('nav_size'+oldsize);
            nava[i].classList.add('nav_size'+navsize);
            //nava[i].style.marginRight = marginr;
        }
        var fontsize = [];
        var fontsize2 = [];
        for (i = 0; i < elementp.length; i++){
            fontsize.push(parseFloat(window.getComputedStyle(elementp[i], null).getPropertyValue('font-size')));
        }
        for (i = 0; i < elementp.length; i++){
            fontsize2.push(fontsize[i] + size + 'px');
            elementp[i].style.fontSize = fontsize2[i];
        }
    }
    $.ajax({
        type: "POST",
        url: controller,
        data:{fontsize: countsize},
    });
}
/*
function openModal(portraitId){
    const img = document.getElementById('portrait'+portraitId);
    const modal = document.getElementById('modal');
    const imgsrc = img.src;
    const footer = document.getElementsByTagName('footer');
    const text1 = document.getElementById('text1_'+portraitId).textContent;
    const text2 = document.getElementById('text2_'+portraitId).textContent;
    footer[0].style.display = "none";
    modal.style.display = "block";
    //console.log(text1);
    document.getElementById('textspeech').innerHTML = text1;
    document.getElementById('authorspeech').innerHTML = text2;

    const content = document.getElementById('modalcontent');
    content.style.backgroundImage = "url("+imgsrc+")";
}
function closeModal(){
    const modal = document.getElementById('modal');
    modal.style.display = "none";
    const footer = document.getElementsByTagName('footer');
    footer[0].style.display = "block";
}
*/
if (document.getElementsByClassName("slideshow-slide").length != 0){
    var slideIndex = 0;
    showSlides();
}

function showSlides() {
    var i;
    var slides = document.getElementsByClassName("slideshow-slide");
    for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
    }
    slideIndex++;
    if (slideIndex > slides.length){slideIndex = 1}
    slides[slideIndex-1].style.display = "block";
    slides[slideIndex-1].classList.add("slide-fade-in");
    setTimeout(showSlides, 5000);
}
function offsetAnchor() {
    if(location.hash.length !== 0) {
        if (window.scrollY + window.innerHeight <= document.body.offsetHeight + 240){
            window.scrollTo(window.scrollX, window.scrollY - 120);
        }
    }
}
/*
function enlargeArticle(id,nr) {
    a = document.getElementById(id+"fulltext"+nr);
    a1 = document.getElementById(id+"showtext"+nr);
    b = document.getElementById(id+"more"+nr);
    c = document.getElementById(id+"less"+nr);
    d = document.getElementById(id+"text"+nr);
    a.classList.toggle("openArticle");
    a.classList.toggle("displayNone");
    a1.classList.toggle("displayNone");
    b.classList.toggle("displayNone");
    c.classList.toggle("displayNone");
    d.classList.toggle("openArticle");
    document.getElementById(id).scrollIntoView();
    window.scrollTo(window.scrollX, window.scrollY - 120);
}
*/

function toggleContainer(id,nr) {
    if ($('#network'+id+"_"+nr).hasClass("open")){
        closeContainer(id,nr);
    } else {
        openContainer(id,nr);
    }
}
function closeContainer(id,nr) {
    $('#network'+id+'_'+nr).removeClass("open");
    delay(300).then(()=>scrollToId("network"+id+'_'+nr));
    $('#text'+id+'_'+nr).slideUp('slow');
}
function openContainer(id,nr) {
    $('#network'+id+'_'+nr).addClass("open");
    $('#text'+id+'_'+nr).slideDown('slow');
    delay(300).then(()=>scrollToId("network"+id+'_'+nr));
    //window.scrollTo(window.scrollX, window.scrollY - 120);
}
function scrollToId(id){
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset - 120;
    window.scrollTo({top: y, behavior: 'smooth'});
}
function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}